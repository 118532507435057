<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="课程名称:">
          <el-input 
						v-model.trim="searchForm.coursename" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
				<el-form-item label="课程类型:">
					<el-select 
						v-model="searchForm.coursetype"
						@visible-change="isintegrity"
						@keyup.enter.native="seach"
						ref="isintegrityref"
						clearable 
					>
						<el-option value="1" label="学生" ></el-option>
						<el-option value="2" label="员工"  :disabled="!$store.state.login.isCourseAuth"></el-option>
					</el-select>
				</el-form-item>
      </el-form>
    </div>
      <div class="r-w">
      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
			<el-table-column
        label="流水号"
        prop="id"
        align="center"
      />
      <el-table-column label="课程类型" align="center" prop="coursetype" width="200">
				<template slot-scope="scope">
			    {{ isCourse[scope.row.coursetype] }}
			  </template>
      </el-table-column>
      <el-table-column
        label="课程名称"
        prop="coursename"
        align="center"
        width="200"
      />
      <el-table-column
        label="排序"
        prop="sort"
        align="center"
        width="80"
      />
      <el-table-column
        label="课程封面图片"
        prop="coursepic"
        align="center"
      >
				<template slot-scope="scope">
        <img 
          :src="scope.row.coursepic ? $url.upload + scope.row.coursepic : scope.row.coursepic" 
            min-width="70" height="70" 
          />
			  </template>
			</el-table-column>
			<el-table-column
        label="操作"
        align="center"
      >
			<template slot-scope="scope" align="center">
          <el-button @click="btnShowDetail(scope.row)" type="text">
							详情
					</el-button>
        </template>
			</el-table-column>
     
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>
    <!--添加，修改-->
    <el-dialog
			class="btn-2b5a95"
			width="650px"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
    >
      <el-form 
				:model="dynamicValidateForm" 
				ref="dynamicValidateForm" 
				class="demo-dynamic form-item-w-100"
				status-icon 
				:rules="rules"
				label-width="100px"
				>
				<el-form-item label="课程类型:" prop="coursetype">
					<el-select 
						v-model="dynamicValidateForm.coursetype"
            clearable
					>
						<el-option :value="1" label="学生"></el-option>
						<el-option :value="2" label="员工"  :disabled="!$store.state.login.isCourseAuth"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="课程名称:" prop="coursename">
          <el-input 
						v-model.trim="dynamicValidateForm.coursename" />
        </el-form-item>
				<el-form-item label="封面展示:" prop="coursepic">
          <el-input v-model="dynamicValidateForm.coursepic" disabled />
          <el-upload
            class="upload-demo"
            accept=".png,.jpg"
            :action="$store.state.global.baseUrl"
							multiple
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="(prop) => uploadFile('coursepic')"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
				<el-form-item label="排序:" prop="sort">
          <el-input 
						v-model.trim="dynamicValidateForm.sort" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
		<!-- 详情 -->
    <el-dialog
      class="btn-2b5a95"
      width="70%"
			v-dialogDrag
      title="详情："
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
			:close-on-click-modal='false'
      append-to-body
    >
    <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
          <el-form-item label="章节:">
            <el-select 
					  	v-model="selectSearchForm.chaptername2"
					  	clearable 
					  >
					  <el-option
					    v-for="(value, key) in map" 
					  	:key="key"
					    :value="key"
					    :label="value"
					  	clearable
					  ></el-option>
					</el-select>
        </el-form-item>
            <el-form-item label="小节:">
              <el-input
                v-model.trim="selectSearchForm.chaptername"
            		@keyup.enter.native="selectSeach"
                clearable
              />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="selectSeach"
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <!-- 操作按钮 -->
      <div class="select-btn" style="margin-bottom: 20px;">
				<el-button type="primary" @click="btnAddDetial">新增章节 </el-button>
        <el-button type="primary" @click="btnAddasDetial">新增小节 </el-button>
        <el-button type="primary" @click="btnEditDetial">修改 </el-button>
        <el-button type="primary" @click="btnDelDetial">删除 </el-button>
				<el-button type="primary" @click="btnImportDetial">导入 </el-button>
        <el-button type="primary" @click="btnReloadDetial">刷新 </el-button>
      </div>
      <el-table
        :data="showTable.list"
        style="width: 100%"
				stripe
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
        ></el-table-column>
        <el-table-column
          label="小节"
          prop="chaptername"
          align="center"
        ></el-table-column>
        <el-table-column
          label="视频id"
          prop="vid"
          align="center"
        ></el-table-column>
				<el-table-column
          label="视频时长"
          prop="length"
          align="center"
        ></el-table-column>
				<el-table-column
          label="排序"
          prop="sort"
          align="center"
        ></el-table-column>
				<el-table-column
          label="所属章节"
          prop="chaptername2"
          align="center"
        ></el-table-column>
				<el-table-column
          label="录入人"
          prop="insertusername"
          align="center"
        ></el-table-column>
      </el-table>

      <el-pagination
        background
        @current-change="handleShowChange"
        :current-page="pageinfo.pageindex"
        :page-size="pageinfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="showTable.totalcount"
      ></el-pagination>
    </el-dialog>

		<el-dialog
			class="btn-2b5a95"
			width="45%"
			v-dialogDrag
			:close-on-click-modal='false'
      title="新增章节"
      :visible.sync="addShowAddModal"
      v-if="addShowAddModal"
      append-to-body
    >
      <el-form 
				:model="myForm" 
				ref="myForm" 
				class="demo-dynamic form-item-w-100"
				status-icon 
				:rules="rules"
				label-width="100px"
				>
				<el-form-item label="章节名称:" prop="chaptername2">
          <el-input 
						v-model.trim="myForm.chaptername2" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitHandleForm('myForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
		
		<el-dialog
			class="btn-2b5a95"
			width="45%"
			v-dialogDrag
			:close-on-click-modal='false'
      title="新增小节"
      :visible.sync="videocoursedetialModal"
      v-if="videocoursedetialModal"
      append-to-body
    >
      <el-form 
				:model="videoForm" 
				ref="videoForm" 
				class="demo-dynamic form-item-w-100"
				status-icon 
				:rules="rules"
				label-width="100px"
				>
				<el-form-item label="章节:" prop="chaptername2">
          <el-select 
						v-model="videoForm.chaptername2"
						clearable 
					>
					<el-option
					  v-for="(value, key) in map" 
						:key="key"
					  :value="value"
					  :label="value"
						clearable
					></el-option>
					</el-select>
        </el-form-item>
				<el-form-item label="小节:" prop="chaptername">
          <el-input 
						v-model.trim="videoForm.chaptername" />
        </el-form-item>
				<el-form-item label="视频id:" prop="vid">
          <el-input 
						v-model.trim="videoForm.vid" />
        </el-form-item>
				<el-form-item label="视频时长:" prop="length">
          <el-input 
						v-model.trim="videoForm.length" />
        </el-form-item>
				<el-form-item label="排序:" prop="sort">
          <el-input 
						v-model.trim="videoForm.sort" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitvideoForm('videoForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
		<!-- 导入 -->
    <Import
      :importDialog="importDialog"
      :importSaveUrl="importSaveUrl"
      @onCloseImport="onCloseImport"
      @getDataList="showList"
      :downloadUrl="downloadUrl"
    ></Import>
  </div>
</template>


<script>
import { _coursetype } from "@/assets/js/filedValueFlag";
import Export from "@/components/Export/index.vue";
import Import from "@/components/Import/index.vue";
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  videocourse_list,
	videocourse_save,
  videocourse_input,
  videocourse_delete,
	videocoursedetiled_list,
	videocoursedetailed_getchapter,
  videocoursedetailed_savechapter,
  videocoursedetialed_save,
	videocoursedetailed_input,
  videocoursedetailsed_delte,
  videocoursedetailed_importsave,
} from "@/request/api/allChildrenProject";
import {
  _pictureupload_save,
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "management",
	components: {
    Import,
  },
  props: {
    videocoursedetailed_importsave: {
      default: () => videocoursedetailed_importsave,
    },
  },
  data() {
    return {
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
			fds: new FormData(),
			map:"",
      fileList: [], //上传的文件列表，仅作展示
      file: new FormData(), //上传的文件表单
			rules: {
				coursepic: [{ trigger: "blur", message: "必填字段", required: true }],
				coursetype: [{ trigger: "blur", message: "必填字段", required: true }],
				coursename: [{ trigger: "blur", message: "必填字段", required: true }],
				sort: [{ trigger: "blur", message: "必填字段", required: true }],
        chaptername2:[{ trigger: "blur", message: "必填字段", required: true }],
        chaptername: [{ trigger: "blur", message: "必填字段", required: true }],
        vid:[{ trigger: "blur", message: "必填字段", required: true }],
        length:[{ trigger: "blur", message: "必填字段", required: true }],
			},
			multipleSelection: [], //选中的表格项
    	  showAddModal: false, //添加
				addShowAddModal: false, //新增添加
    	  dynamicValidateForm: {
	  	}, //表单
			myForm: {},
    	dialogTitle: "", //对话框标题
			isCourse: _coursetype,
			showDetailModal: false, //详情
			showTable: {},
			videoForm: {},
			videocoursedetialModal: false,
			importSaveUrl: "",
      downloadUrl: "",
			importDialog: false, //导入框
      selectSearchForm: {}, // 选择搜索
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      }, // 选择框分页
      selectTableUrl: "",
    };
  },
  created() {
    this.getDataList();
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["dic_combox"]),
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		isintegrity(e) {
			if(!e) {
				this.$refs.isintegrityref.blur();
			}
		},
		reset() {
			this.searchForm = {
				coursename: null,
				coursetype: null,
			}
		},
		//重置
		resetQuery() {
			this.reset();
			this.getDataList();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: videocourse_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
		//上传文件前 before
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile(prop) {
      myRequest({
        method: "post",
        url: _pictureupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm[prop] = res.data.url;
        }
      });
    },
		//查看详情 click
		btnShowDetail(row) {
		 this.itemRow = row;
		 this.showList();
		},
     // 搜索
     selectSeach() {
			this.selectPageInfo.pageindex = 1;
      this.showList();
    },
    selectQuery() {
			this.selectSearchForm = {
        chaptername2: null,
        chaptername: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(videocoursedetiled_list, null,{
        videocourseid: this.itemRow.id,
		    });
		},
		//切换查看表格页
    handleShowChange(val) {
      this.pageinfo.pageindex = val;
      this.showList();
      this.onCloseSelectadd();
    },
    onCloseSelectadd() {
      this.selectPageInfo.pageindex = 1;
    },
		//查看详情 api
		showList() {
      this.selectSearchForm.questionid = this.itemRow.id,
      myRequest({
        url: videocoursedetiled_list,
        data: Object.assign(this.selectSearchForm, this.selectPageInfo)
      }).then((res) => {
        if (res.data.code === "200") {
          this.showTable = res.data.data;
					this.showDetailModal = true;
          this.getchapter();
        }
      });
    },
		  //关闭导出框
		onCloseExport(bool) {
      this.transferDialog = false;
    },
		//关闭导入框
    onCloseImport(bool) {
      this.importDialog = bool;
    },
		// 导入
		btnImportDetial() {
			this.importSaveUrl = videocoursedetailed_importsave;
      this.downloadUrl = "download/教学管理/学历/视频导入模板.xls";
      this.importDialog = true;
			// this.showList();
		},
		 //查看详情的刷新 click
		btnReloadDetial() {
      this.showList()
    },
		btnAddDetial() {
			this.myForm = {
			
			}
			this.addShowAddModal = true;
		},
		getchapter() {
			myRequest({
        url: videocoursedetailed_getchapter,
        data: {
          videocourseid: this.itemRow.id
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.map = res.data.data.list;
					for(const key in this.map){
						console.log(key,'key')
						console.log('value',this.map[key])
					}
        }
      });
		},
    btnAddasDetial() {
			this.videocoursedetialModal = true;
			this.getchapter();
			this.videoForm = {
        videocourseid: this.itemRow.id,
			}
		},
		
		//查看详情的修改 click
    btnEditDetial() {
      selectCheck(this.multipleSelection, "修改", false, () => {
				this.editVideoForm(this.multipleSelection[0].id);
      });
    },
		editVideoForm(id) {
      myRequest({
        url: videocoursedetailed_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
					this.videocoursedetialModal = true;
					this.videoForm = res.data.data.vvideocoursedetailed;
          delete this.videoForm.inserttime;
          this.$set(
			        this.videoForm,
			        "inserttime",
			        formatDare(
			          this.videoForm.inserttime,
			          "YYYY-MM-DD HH:mm:ss"
			        )
			      );
				}
      });
    },
		//添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
      };
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
		 //删除click
		 btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
		//删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: videocourse_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
		 //查看详情的删除 click
		 btnDelDetial() {
      selectCheck(this.multipleSelection, "删除", true, () => {
				confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTable(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
		
		delTable(idArr) {
      myRequest(
        {
          method: "post",
          url: videocoursedetailsed_delte,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }).then((res) => {
          if(res.data.code == "200") {
            this.showList();
          }
        })
         
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //选项表格 api
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
          this[modal] = true;
        }
      });
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: videocourse_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
					this.showAddModal = true;
					this.dynamicValidateForm = res.data.data.vvideocourse;
				}
      });
    },
		submitvideoForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          delete this.videoForm.inserttime;
					myRequest({
					url: videocoursedetialed_save,
					data: this.videoForm,
						},
						{
			  		isRefTableList: true,
			  		that: this,

			  		modal: "videocoursedetialModal",
					}).then((res) => {
            if(res.data.code == "200") {
              this.showList();
            }
          })
        }
      });
    },
		 //提交-（添加，修改） api
		 submitHandleForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
					myRequest({
					url: videocoursedetailed_savechapter,
					data: {
						videocourseid: this.itemRow.id,
						chaptername2: this.myForm.chaptername2
					}
					},
					{
			  		isRefTableList: true,
			  		that: this,

			  		modal: "addShowAddModal",
				})
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
					this.submitForm();
        }
      });
    },
		
		//提交-（添加，修改） api
		submitForm() {
			myRequest({
				method: "post",
				url: videocourse_save,
				data: this.$qs.stringify(this.dynamicValidateForm, {
					arrayFormat: 'repeat'
			}),
			},{
			  isRefTableList: true,
			  that: this,
			
			  modal: "showAddModal",
			})
		},
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>



<style lang="scss">
	
</style>